<template>
  <div class="contact-details" t-id="contact-details">
    <telia-grid>
      <telia-heading tag="h2" variant="title-200" class="contact-details-heading">{{
        t("contactDetails.heading")
      }}</telia-heading>
      <telia-p t-id="component-description"
        >{{ t("contactDetails.description.part1")
        }}<a href="/privat/om/integritetspolicy">{{ t("contactDetails.description.linkText") }}</a
        >{{ t("contactDetails.description.part2") }}</telia-p
      >
      <div v-if="!loading" class="contact-container">
        <div v-if="emails && emails.length > 0">
          <div class="contact-container-detail" v-for="email in emails" :key="email.id">
            <contact-details-edit
              :contact="email"
              :scope-id="props.scopeId"
              :defaultEdit="false"
              :deletable="emails.length > 1"
              @contact-updated="update()"
            />
          </div>
        </div>
        <div v-else>
          <contact-details-edit
            :contact="{ type: 'EMAIL', value: '' }"
            :defaultEdit="true"
            :deletable="false"
            @contact-updated="update()"
          />
        </div>

        <div v-if="phonenumbers && phonenumbers.length > 0">
          <div
            class="contact-container-detail"
            v-for="phonenumber in phonenumbers"
            :key="phonenumber.id"
          >
            <contact-details-edit
              :contact="phonenumber"
              :defaultEdit="false"
              :deletable="true"
              @contact-updated="update()"
            />
          </div>
        </div>
        <div v-else>
          <contact-details-edit
            :contact="{ type: 'PHONENUMBER', value: '' }"
            :defaultEdit="false"
            :deletable="false"
            @contact-updated="update()"
          />
        </div>
      </div>
      <div v-else>
        <telia-skeleton class="loading-skeleton" />
      </div>
    </telia-grid>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref } from "vue";
import { logError } from "@telia/b2x-logging";
import { translateMixin } from "../locale";
import type { Contact } from "../services/contact-service";
import { getContacts } from "../services/contact-service";
import contactDetailsEdit from "./contact-details-edit.vue";

const t = translateMixin.methods.t;

interface Props {
  scopeId: string;
}

const props = defineProps<Props>();

let loading: Ref<boolean> = ref(false);
let emails: Ref<Array<Contact>> = ref([]);
let phonenumbers: Ref<Array<Contact>> = ref([]);

let update = async () => {
  await getContactDetails();
};

const getContactDetails = () => {
  loading.value = true;
  getContacts(props.scopeId)
    .then((res) => {
      emails.value = res.filter((entry) => entry.type === "EMAIL") as Array<Contact>;
      phonenumbers.value = res.filter((entry) => entry.type === "PHONENUMBER") as Array<Contact>;
    })
    .catch(() => {
      emails.value = [];
      phonenumbers.value = [];
      logError("b2b-my-profile", "Failed to get contacts");
    })
    .finally(() => {
      loading.value = false;
    });
};

getContactDetails();
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/breakpoints/variables";

.contact-details-heading {
  margin-bottom: $telia-spacing-12;
}

.contact-container {
  margin-top: $telia-spacing-48;
}

.contact-container-detail {
  margin-bottom: $telia-spacing-24;
}

.loading-skeleton {
  margin-top: $telia-spacing-32;
  width: 60%;
  height: $telia-spacing-128;
}

@media screen and (max-width: $telia-breakpoint-medium) {
  .loading-skeleton {
    margin-top: $telia-spacing-32;
    width: 100%;
    height: $telia-spacing-128;
  }
}
</style>
