<template>
  <b2b-layout
    :heading="t('MY_PROFILE.TITLE')"
    show-organisation-selector="false"
    :page-title="t('MY_PROFILE.DOCUMENT_TITLE')"
  >
    <div slot="header">
      <telia-grid>
        <telia-row>
          <telia-col width="12">
            <telia-text-spacing class="header-slot-content">
              <telia-skeleton
                v-if="state === STATE.LOADING"
                t-id="page-description-skeleton"
                class="page-description-skeleton"
              />
              <!-- preamble-200 according to UX guidelines -->
              <telia-p
                v-else
                t-id="page-description"
                class="page-description"
                variant="preamble-200"
                disable-hyphenation="true"
              >
                {{ pageDescription }}
              </telia-p>
            </telia-text-spacing>
          </telia-col>
        </telia-row>
      </telia-grid>
    </div>
    <skeletons v-if="state === STATE.LOADING" />
    <error-notification v-else-if="state === STATE.FETCH_ACCOUNT_DETAILS_ERROR" />
    <div v-else-if="state !== STATE.NEUTRAL" class="my-profile">
      <account-information
        :state="state"
        :account-information-data="accountInformationData"
        :scope-id="scopeId"
      />
      <account-merge-notification
        :account-upgrade-result="accountUpgradeResult"
        :non-merged-group-names="nonMergedGroupNames"
      />
      <contact-details :scope-id="scopeId" />
    </div>
    <!-- else: NEUTRAL, display nothing -->
  </b2b-layout>
</template>

<script setup lang="ts">
import { translateSetup, translateMixin } from "./locale";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { corpLogin, corpUserAccount } from "@telia/b2b-rest-client";
import { computed, onBeforeMount, ref } from "vue";
import type { Ref } from "vue";
import errorNotification from "./components/error-notification.vue";
import skeletons from "./components/skeletons.vue";
import accountInformation from "./components/account-information.vue";
import contactDetails from "./components/contact-details.vue";
import accountMergeNotification from "./components/account-merge-notification.vue";
import "@telia/b2b-layout";
import { logError } from "@telia/b2x-logging";

const t = translateMixin.methods.t;

const STATE = {
  LOADING: "LOADING",
  NEUTRAL: "NEUTRAL",
  FETCH_ACCOUNT_DETAILS_ERROR: "FETCH_ACCOUNT_DETAILS_ERROR",
  FEDERATED: "FEDERATED",
  BANKID: "BANKID",
  PASSWORD: "PASSWORD",
};

const scopeId = ref("");
const state = ref(STATE.LOADING);

// similar to state, but if possible, this shows the correct page description.
const descriptionState = ref(STATE.LOADING);

const accountUpgradeResult = ref<string>("");
const nonMergedGroupNames = ref<string>("");

const accountInformationData: Ref<{
  userName: string | undefined;
  firstName: string;
  lastName: string;
}> = ref({
  userName: undefined,
  firstName: "",
  lastName: "",
});

onBeforeMount(async () => {
  tryToInitializePage();
});

const tryToInitializePage = async () => {
  try {
    await translateSetup(["mybusiness"]);
    let authMethod = await getAuthMethod();

    try {
      if (authMethod !== STATE.NEUTRAL) {
        scopeId.value = await getScopeIdOrThrow();
        accountInformationData.value = await fetchAccountInformationData();

        // Account merge notification, done by external part
        const pageUrl = new URL(window.location.href);
        accountUpgradeResult.value = pageUrl.searchParams.get("upgrade-result") ?? "";
        nonMergedGroupNames.value = pageUrl.searchParams.get("failed-groupnames") ?? "";
      }

      // set state only when everything has finished as it will hide the skeleton and reveal everything
      state.value = authMethod;
    } catch {
      state.value = STATE.FETCH_ACCOUNT_DETAILS_ERROR;
    }
    descriptionState.value = authMethod;
  } catch {
    state.value = STATE.FETCH_ACCOUNT_DETAILS_ERROR;
    descriptionState.value = STATE.FETCH_ACCOUNT_DETAILS_ERROR;
  }
};

const pageDescription = computed(() => {
  switch (descriptionState.value) {
    case STATE.BANKID:
      return t("MY_PROFILE.PAGE_DESCRIPTION_BANKID");
    case STATE.FEDERATED:
      return t("MY_PROFILE.PAGE_DESCRIPTION_FEDERATED");
    case STATE.PASSWORD:
      return t("MY_PROFILE.PAGE_DESCRIPTION_PASSWORD");
    default:
      // NEUTRAL or ANY ERRORS.
      return t("MY_PROFILE.PAGE_DESCRIPTION_NEUTRAL");
  }
});

const fetchAccountInformationData = async () => {
  try {
    const backendResponse = await corpUserAccount.UserAccountApiService.getAccountInformation(
      scopeId.value
    );

    return {
      firstName: backendResponse.firstName ?? "",
      lastName: backendResponse.lastName ?? "",
      userName: backendResponse.userName ?? undefined,
    };
  } catch (error) {
    logError("b2b-my-profile", "Failed to fetch account information");
    throw error;
  }
};

const getAuthMethod = async () => {
  try {
    const isLoggedinResponse = await corpLogin.IsLoggedInControllerService.isLoggedIn();

    if (isLoggedinResponse.authMethods?.includes(STATE.FEDERATED)) return STATE.FEDERATED;
    if (isLoggedinResponse.authMethods?.includes(STATE.BANKID)) return STATE.BANKID;
    if (isLoggedinResponse.authMethods?.includes(STATE.PASSWORD)) return STATE.PASSWORD;
    return STATE.NEUTRAL;
  } catch (error) {
    logError("b2b-my-profile", "Failed to fetch isloggedin");
    throw error;
  }
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/breakpoints/mixins";
@import "@teliads/components/foundations/spacing/variables";

.page-description-skeleton {
  height: 6.4rem;
}
</style>
