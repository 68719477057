<template>
  <div v-if="!editing">
    <div class="content">
      <div class="leftContent">
        <telia-p class="contact-details-type" variant="overline-100">{{
          t(`contactDetails.${contact.type}`)
        }}</telia-p>
        <telia-p data-testid="contact-value">{{
          contact.type === "EMAIL" ? contact.value : getPhoneNumber()
        }}</telia-p>
      </div>
      <div class="rightContent">
        <telia-button data-testid="contact-change" variant="text" @click="toggle()">{{
          contact.value === "" ? t("contactDetails.addPhoneNumber") : t("contactDetails.change")
        }}</telia-button>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="content background-grey">
      <div class="leftContent">
        <telia-text-input
          v-if="contact.type === 'EMAIL'"
          :value="inputValue"
          :label="t(`contactDetails.${contact.type}`)"
          type="email"
          autocomplete="email"
          inputmode="email"
          required="true"
          :required-error-message="t('contactDetails.emailAddressEmpty')"
          :errorMessage="invalidEmailAddress ? t('contactDetails.emailAddressInvalid') : undefined"
          @input="(event) => (inputValue = event.target.value)"
        />
        <telia-text-input
          v-else
          :value="inputValue"
          :label="t(`contactDetails.${contact.type}`)"
          type="tel"
          autocomplete="tel"
          inputmode="tel"
          required="true"
          :required-error-message="t('contactDetails.phoneNumberEmpty')"
          :errorMessage="invalidPhoneNumber ? t('contactDetails.phoneNumberInvalid') : undefined"
          @input="(event) => (inputValue = event.target.value)"
        />
      </div>
      <div class="rightContent">
        <b2x-loading-button
          class="contact-details-button"
          variant="primary"
          :disabled="disabled || inputValue === contact.value"
          :is-loading="disabled"
          @click="saveEntry(contact, inputValue)"
          >{{ t("contactDetails.save") }}</b2x-loading-button
        >
        <b2x-loading-button
          v-if="deletable"
          class="contact-details-button"
          variant="secondary"
          :disabled="deleteDisabled"
          :is-loading="deleteDisabled"
          @click="deleteEntry(contact)"
          >{{ t("contactDetails.delete") }}</b2x-loading-button
        >
        <telia-button
          class="contact-details-button"
          variant="tertiary-purple"
          :disabled="disabled"
          @click="toggle()"
        >
          {{ t("contactDetails.cancel") }}</telia-button
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { onMounted, ref, Ref } from "vue";
import { logError } from "@telia/b2x-logging";
import { translateMixin } from "../locale";
import { updateContact, addContact, deleteContact } from "../services/contact-service";
import type { Contact } from "../services/contact-service";

const t = translateMixin.methods.t;

let editing = ref(false);
let disabled: Ref<boolean> = ref(false);
let deleteDisabled: Ref<boolean> = ref(false);
let inputValue: Ref<string> = ref("");

let invalidEmailAddress: Ref<boolean> = ref(false);
let invalidPhoneNumber: Ref<boolean> = ref(false);

const props = defineProps({
  contact: { type: Object as PropType<Contact>, required: true },
  defaultEdit: { type: Boolean, default: false },
  deletable: { type: Boolean, default: false },
});

onMounted(() => {
  if (props.contact.type === "PHONENUMBER") {
    inputValue.value = props.contact.value === "" ? "+46 " : props.contact.value;
  } else {
    inputValue.value = props.contact.value;
  }

  editing.value = props.defaultEdit;
});

const toggle = () => {
  editing.value = !editing.value;
};

const emit = defineEmits(["contact-updated"]);

const saveEntry = async (contact: Contact, newValue: string) => {
  disabled.value = true;
  if (contact.id) {
    await updateContact(contact, newValue)
      .then(() => {
        emit("contact-updated");
      })
      .catch((error) => {
        invalidEmailAddress.value = error?.body?.translationKey === "INVALID_EMAIL";
        invalidPhoneNumber.value = error?.body?.translationKey === "INVALID_PHONENUMBER";
        logError("b2b-my-profile", "Failed to update contact");
      })
      .finally(() => {
        disabled.value = false;
      });
  } else {
    await addContact(contact.type || "", newValue)
      .then(() => {
        emit("contact-updated");
      })
      .catch((error) => {
        invalidEmailAddress.value = error?.body?.translationKey === "INVALID_EMAIL";
        invalidPhoneNumber.value = error?.body?.translationKey === "INVALID_PHONENUMBER";
        logError("b2b-my-profile", "Failed to add contact");
      })
      .finally(() => {
        disabled.value = false;
      });
  }
};

const deleteEntry = async (contact: Contact) => {
  deleteDisabled.value = true;
  deleteContact(contact)
    .then(() => {
      emit("contact-updated");
    })
    .catch(() => {
      logError("b2b-my-profile", "Failed to delete contacts");
    })
    .finally(() => {
      deleteDisabled.value = false;
    });
};

const getPhoneNumber = () => {
  return props.contact.value === "" ? t("contactDetails.missingPhoneNumber") : props.contact.value;
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/mixins";
@import "@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/breakpoints/variables";

.contact-details-type {
  color: $telia-gray-500;
}

.contact-details-button {
  display: inline-block;
  margin-top: $telia-spacing-24;
  &:not(:last-child) {
    margin: 0 0 0 $telia-spacing-16;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 790px;
  margin: $telia-spacing-24 0;
}

.leftContent {
  min-width: 400px;
}

.background-grey {
  padding: $telia-spacing-12 $telia-spacing-24 $telia-spacing-24;
  background-color: $telia-gray-100;
}

@media screen and (max-width: $telia-breakpoint-medium) {
  .leftContent {
    min-width: 100%;
  }

  telia-text-input {
    width: 100%;
  }

  .contact-details-button {
    display: inline-block;
    margin-top: $telia-spacing-24;
    &:first-child {
      margin: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
    margin: $telia-spacing-24 0;
  }

  .background-grey {
    padding: $telia-spacing-12 $telia-spacing-24 $telia-spacing-24;
  }
}

@media screen and (min-width: $telia-breakpoint-medium) {
  telia-text-input {
    width: 100%;
  }
}
</style>
