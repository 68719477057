<template>
  <telia-grid>
    <telia-row>
      <telia-col width="12">
        <telia-skeleton class="my-profile__account-information-skeleton"></telia-skeleton>

        <telia-skeleton class="my-profile__account-name-skeleton"></telia-skeleton>
        <telia-skeleton class="my-profile__name-skeleton"></telia-skeleton>

        <telia-skeleton class="my-profile__auth-method-heading-skeleton"></telia-skeleton>
        <telia-skeleton class="my-profile__auth-method-skeleton"></telia-skeleton>

        <telia-skeleton class="my-profile__contact-details-heading-skeleton"></telia-skeleton>
        <telia-skeleton class="my-profile__contact-details-preamble-skeleton"></telia-skeleton>
        <telia-skeleton class="my-profile__contact-details-form-skeleton"></telia-skeleton>
      </telia-col>
    </telia-row>
  </telia-grid>
</template>
<script setup lang="ts"></script>
<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/mixins";
@import "@teliads/components/foundations/spacing/variables";
@import "@teliads/components/foundations/breakpoints/mixins";
@import "@teliads/components/foundations/borders/variables";

.my-profile {
  &__account-information-skeleton {
    width: 30rem;
    height: 4.8rem;
    margin: 0;
  }
  &__account-name-skeleton,
  &__auth-method-heading-skeleton {
    height: 1.5rem;
    margin: 2.8rem 0 0.1rem 0;
  }

  &__account-name-skeleton {
    width: 10rem;
  }
  &__auth-method-heading-skeleton {
    width: 20rem;
  }

  &__name-skeleton,
  &__auth-method-skeleton {
    height: 2.5rem;
    margin-bottom: 0.1rem;
  }

  &__name-skeleton {
    width: 25rem;
  }

  &__auth-method-skeleton {
    width: 15rem;
    margin-bottom: 6rem;
  }

  &__contact-details-heading-skeleton {
    width: 30rem;
    height: 4rem;
    margin-bottom: $telia-spacing-12;
  }
  &__contact-details-preamble-skeleton {
    width: 100%;
    height: 5rem;
  }

  // same as in contact-details.vue
  &__contact-details-form-skeleton {
    margin-top: $telia-spacing-32;
    width: 60%;
    height: $telia-spacing-128;
  }

  @media screen and (max-width: $telia-breakpoint-medium) {
    &__contact-details-form-skeleton {
      margin-top: $telia-spacing-32;
      width: 100%;
      height: $telia-spacing-128;
    }
  }
}
</style>
