import { corpUserAccount, corpScopeInformation } from "@telia/b2b-rest-client";

import type { Contact } from "./contact-mapper";

export type { Contact } from "./contact-mapper";

export const getContacts = async (scopeId: string) => {
  return await corpUserAccount.UserAccountApiService.getContacts(scopeId || "");
};

export const updateContact = async (contact: Contact, newValue: string) => {
  const reqBody = {
    id: contact.id,
    type: contact.type,
    newValue: newValue,
  };

  const scopeId = (
    await corpScopeInformation.ScopeControllerService.getScopeForLoggedInUser(
      "LOGGED_IN_USER_NO_SCOPE"
    )
  ).customerScope;

  return await corpUserAccount.UserAccountApiService.updateContact(scopeId || "", reqBody);
};

export const addContact = async (type: string, newValue: string) => {
  const reqBody = {
    type: type,
    newValue: newValue,
  };

  const scopeId = (
    await corpScopeInformation.ScopeControllerService.getScopeForLoggedInUser(
      "LOGGED_IN_USER_NO_SCOPE"
    )
  ).customerScope;

  return await corpUserAccount.UserAccountApiService.addContact(scopeId || "", reqBody);
};

export const deleteContact = async (contact: Contact) => {
  const reqBody = {
    id: contact.id,
  };

  const scopeId = (
    await corpScopeInformation.ScopeControllerService.getScopeForLoggedInUser(
      "LOGGED_IN_USER_NO_SCOPE"
    )
  ).customerScope;

  return await corpUserAccount.UserAccountApiService.deleteContact(scopeId || "", reqBody);
};
