<template>
  <div>
    <telia-grid>
      <div class="my-profile__notification-wrapper">
        <telia-notification t-id="fetch-account-details-error" status="warning" heading-tag="h2">
          <telia-p slot="heading">
            {{ t("MY_PROFILE.ERROR_FETCHING_INFO") }}
          </telia-p>
        </telia-notification>
      </div>
    </telia-grid>
  </div>
</template>

<script setup lang="ts">
import { translateMixin } from "../locale";
const t = translateMixin.methods.t;
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

.my-profile__notification-wrapper {
  margin: $telia-spacing-24 0;
}
</style>
