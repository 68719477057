<template>
  <telia-grid>
    <telia-row>
      <telia-col width="12">
        <div class="my-profile__account-info-wrapper">
          <telia-heading tag="h2" variant="title-300" class="my-profile__account-info-heading">{{
            t("MY_PROFILE.ACCOUNT_INFORMATION")
          }}</telia-heading>

          <div class="my-profile__account-info-attribute">
            <telia-p t-id="account-fullname-header" variant="overline-100">{{
              t("MY_PROFILE.NAME")
            }}</telia-p>
            <telia-p variant="preamble-100" t-id="account-fullname"
              >{{ accountInformationData.firstName }} {{ accountInformationData.lastName }}</telia-p
            >
            <telia-p v-if="state === 'PASSWORD'" t-id="account-username"
              ><telia-visually-hidden>{{ t("MY_PROFILE.ACCOUNT_NAME") }}</telia-visually-hidden>
              {{ accountInformationData.userName }}</telia-p
            >
          </div>
          <div class="my-profile__account-info-attribute">
            <telia-p t-id="account-authmethod-header" variant="overline-100">{{
              t("MY_PROFILE.AUTH_METHOD")
            }}</telia-p>
            <telia-p variant="preamble-100" t-id="account-authmethod">{{
              authMethodDisplayName
            }}</telia-p>
          </div>
        </div>

        <div v-if="state === 'PASSWORD'">
          <div t-id="update-2fa" class="link-group">
            <telia-button
              :text="t('MY_PROFILE.CHANGE_2FA_DETAILS_LINK')"
              variant="text"
              type="button"
              @click="goToUpdate2faDetails"
              :right-icon="JSON.stringify({ name: 'arrow-right', size: 'sm' })"
            />
            <telia-p variant="paragraph-100">{{ t("MY_PROFILE.CHANGE_2FA_DETAILS_TEXT") }}</telia-p>
          </div>

          <div t-id="upgrade-bank-id" class="link-group">
            <telia-button
              :text="t('MY_PROFILE.CHANGE_TO_BANKID_LINK')"
              variant="text"
              type="button"
              @click="goToUpgradeToBankIdUrl"
              :right-icon="JSON.stringify({ name: 'arrow-right', size: 'sm' })"
            />
            <telia-p variant="paragraph-100">{{ t("MY_PROFILE.CHANGE_TO_BANKID_TEXT") }}</telia-p>
          </div>
        </div>
      </telia-col>
    </telia-row>
  </telia-grid>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from "vue";

import { translateMixin } from "../locale";

import { currentLanguage } from "@telia/b2b-i18n";
import { logError } from "@telia/b2x-logging";
import { action, trackEvent } from "@telia/b2b-web-analytics-wrapper";

import { corpLogin, corpUserAccount } from "@telia/b2b-rest-client";

const t = translateMixin.methods.t;

interface Props {
  accountInformationData: {
    userName: string | undefined;
    firstName: string;
    lastName: string;
  };
  scopeId: string;
  state: string; // "PASSWORD" | "FEDERATED" | "BANKID" | "NEUTRAL";
}

const props = defineProps<Props>();

const thisPageUrl: ComputedRef<string> = computed<string>(
  () => `https://www.telia.se/foretag/mybusiness/${props.scopeId}/installningar/minprofil`
);

const goToUpdate2faDetails = async () => {
  try {
    trackEvent("MB_profile_settings", action.INITIATED, "update_telia_profile");
    const response = await corpUserAccount.TwoFactorAuthorizationApiService.get2FaUpdateUrl(
      props.scopeId,
      thisPageUrl.value,
      currentLanguage() === 'sv' ? 'sv' : 'en'
    );
    window.location.replace(new URL(response.url ?? ""));
  } catch (error) {
    logError("b2b-my-profile", "Failed to fetch url to change 2FA details");
    throw error;
  }
};

const goToUpgradeToBankIdUrl = async () => {
  try {
    trackEvent("MB_switch_login_method", action.INITIATED, "bank_id");
    const response = await corpLogin.UpgradeToBankIdControllerService.upgradeBankId(
      {
        targetUrl: thisPageUrl.value,
      },
      currentLanguage()
    );
    const redirectUrlWihUpgradeToBankID = new URL(response.redirectUrl ?? "");
    window.location.replace(redirectUrlWihUpgradeToBankID);
  } catch (error) {
    logError("b2b-my-profile", "Failed to fetch loginUrl");
    throw error;
  }
};

const authMethodDisplayName = computed(() => {
  switch (props.state) {
    case "BANKID":
      return t("MY_PROFILE.AUTH_METHOD_BANKID");
    case "FEDERATED":
      return t("MY_PROFILE.AUTH_METHOD_FEDERATED");
    case "PASSWORD":
      return t("MY_PROFILE.AUTH_METHOD_PASSWORD");
    case "NEUTRAL":
    default:
      return t("MY_PROFILE.AUTH_METHOD_NEUTRAL");
  }
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/mixins";
@import "@teliads/components/foundations/spacing/variables";

.my-profile__account-info-heading,
.my-profile__account-info-attribute {
  margin-bottom: $telia-spacing-32;
}

.my-profile__account-info-attribute {
  telia-p:first-of-type {
    color: $telia-gray-500;
  }
}

.link-group {
  margin-bottom: $telia-spacing-16;
}

telia-grid {
  margin-bottom: $telia-spacing-32;
}
</style>
