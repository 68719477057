<template>
  <telia-grid v-if="showAccountMergeMessage">
    <telia-notification
      t-id="account-merge-notification"
      :status="messageStatus"
      heading-tag="h4"
      class="account_merge_notification"
    >
      <telia-p slot="heading">
        <strong>{{ notificationTexts.heading }}</strong>
      </telia-p>
      <telia-p slot="content">
        {{ notificationTexts.content }}
      </telia-p>
    </telia-notification>
  </telia-grid>
</template>

<script setup lang="ts">
import { translateMixin } from "../locale";
import { computed } from "vue";

interface Props {
  accountUpgradeResult: string;
  nonMergedGroupNames: string;
}
const props = defineProps<Props>();

const t = translateMixin.methods.t;

const showAccountMergeMessage = computed(() => {
  // Only SUCCESS_MERGED and ERROR will yield a message
  switch (props.accountUpgradeResult) {
    case "SUCCESS_MERGED":
    case "ERROR":
      return true;
    case "SUCCESS":
    default:
      // "" etc.
      return false;
  }
});

const messageStatus = computed(() => {
  if (props.accountUpgradeResult === "ERROR") {
    return "warning";
  }
  return "information";
});

const notificationTexts = computed(() => {
  if (props.accountUpgradeResult === "SUCCESS_MERGED") {
    return {
      heading: t("MY_PROFILE.ACCOUNT_MERGE_HEADER_SUCCESS_MERGED"),
      content: t("MY_PROFILE.ACCOUNT_MERGE_MESSAGE_SUCCESS_MERGED"),
    };
  }

  // props.accountUpgradeResult === "ERROR"
  if (props.nonMergedGroupNames && props.nonMergedGroupNames.length > 0) {
    return {
      heading: t("MY_PROFILE.ACCOUNT_MERGE_HEADER_ERROR"),
      content: t("MY_PROFILE.ACCOUNT_MERGE_MESSAGE_ERROR", {
        groupNames: props.nonMergedGroupNames
          ? props.nonMergedGroupNames.split(",").join(", ")
          : "",
      }),
    };
  }
  return {
    heading: t("MY_PROFILE.ACCOUNT_MERGE_HEADER_ERROR"),
    content: t("MY_PROFILE.ACCOUNT_MERGE_MESSAGE_ERROR_WITHOUT_GROUPS"),
  };
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

.account_merge_notification {
  display: block;
  max-width: 40rem;
  margin-bottom: $telia-spacing-64;
}
</style>
